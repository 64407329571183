import LatestArticleMobile from './latest_article_mobile'
import LatestArticleDesktop from './latest_article_desktop'

const LatestArticle = ({ is_mobile, dataArticleLatest }) => {
  const props = {
    is_mobile: is_mobile || false,
    dataArticleLatest: Array.isArray(dataArticleLatest) ? dataArticleLatest : [],
    moreURL:
      process.env.NEXT_PUBLIC_ROOT_DOMAIN +
      '/recent',
  }

  if (props.is_mobile) {
    return <LatestArticleMobile {...props} />
  } else {
    return <LatestArticleDesktop {...props} />
  }
}

export default LatestArticle
