import ArticleCardHome from 'Components/article_card_home'
import ButtonMore from '@fdn/button_more'
import ErrorPlaceholder from '@fdn/error_placeholder'
import {handleUrlArticle} from 'Helpers/utils'

const LatestArticleDesktop = ({
  is_mobile,
  dataArticleLatest,
  moreURL,
}) => {
  return (
    <div className="latest-article section">
      <div className="section-title-wrapper">
        <h3 className="section-title bordered">Latest Article</h3>
      </div>
      <div className="latest-article-wrapper">
        {Array.isArray(dataArticleLatest) ? (
          dataArticleLatest.length ? (
            <>
              <div className="latest-article-small col">
                {dataArticleLatest.slice(0, 2).map((item, idx) => (
                  <div id={`id_article_${item.id}`} key={idx}>
                    <ArticleCardHome
                      is_mobile={is_mobile}
                      is_sponsored={false}
                      font_title={`Josefin Sans, sans-serif`}
                      article={Object.assign({
                        category: {
                          name: Array.isArray(item?.parent_category_slugs) && item?.parent_category_slugs?.[0]?.replace(
                            /[-_]/g,
                            ' ',
                          ) || "",
                        },
                        date: item.created_at,
                        title: item.title,
                        text: item.excerpt,
                        image: item.image,
                        url: handleUrlArticle(item.url),
                        author: { fullname: item.author.name },
                      })}
                      url_category={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/category/${Array.isArray(item?.parent_category_slugs) && item?.parent_category_slugs?.[0]?.replace(
                        /[-_]/g,
                        '-',
                      ) || ""}`}
                      type={'type-2'}
                    />
                  </div>
                ))}
              </div>
              <div className="latest-article-big">
                {dataArticleLatest.slice(2, 3).map((item, idx) => (
                  <div id={`id_article_${item.id}`} key={idx}>
                    <ArticleCardHome
                      is_mobile={is_mobile}
                      is_sponsored={false}
                      font_title={`Josefin Sans, sans-serif`}
                      article={Object.assign({
                        category: {
                          name: Array.isArray(item?.parent_category_slugs) && item?.parent_category_slugs?.[0]?.replace(
                            /[-_]/g,
                            ' ',
                          ) || "",
                        },
                        date: item.created_at,
                        title: item.title,
                        text: item.excerpt,
                        image: item.image,
                        url: handleUrlArticle(item.url),
                        author: { fullname: item.author.name },
                      })}
                      url_category={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/category/${Array.isArray(item?.parent_category_slugs) && item?.parent_category_slugs?.[0]?.replace(
                        /[-_]/g,
                        '-',
                      ) || ""}`}
                      url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/author/${item.author.username}`}
                      type={'type-2'}
                      size="big"
                    />
                  </div>
                ))}
                <div className="btn-more-wrapper">
                  <ButtonMore
                    text={'SEE ALL ARTICLES'}
                    font_family={`Josefin Sans, sans-serif`}
                    font_size={16}
                    font_weight={400}
                    color={'#000000'}
                    link={moreURL}
                    element_id={'id_see_more_article_latest'}
                  />
                </div>
              </div>
              <div className="latest-article-small col">
                {dataArticleLatest.slice(3, 5).map((item, idx) => (
                  <div id={`id_article_${item.id}`} key={idx}>
                    <ArticleCardHome
                      is_mobile={is_mobile}
                      is_sponsored={false}
                      font_title={`Josefin Sans, sans-serif`}
                      article={Object.assign({
                        category: {
                          name: Array.isArray(item?.parent_category_slugs) && item?.parent_category_slugs?.[0]?.replace(
                            /[-_]/g,
                            ' ',
                          ) || "",
                        },
                        date: item.created_at,
                        title: item.title,
                        text: item.excerpt,
                        image: item.image,
                        url: handleUrlArticle(item.url),
                        author: { fullname: item.author.name },
                      })}
                      url_category={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/category/${Array.isArray(item?.parent_category_slugs) && item?.parent_category_slugs?.[0]?.replace(
                        /[-_]/g,
                        '-',
                      ) || ""}`}
                      url_author={`${process.env.NEXT_PUBLIC_ROOT_DOMAIN}/blog/author/${item.author.username}`}
                      type={'type-2'}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : (
            <ErrorPlaceholder
              is_mobile={is_mobile}
              icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/no-data-icon.svg"
              icon_alt="no data icon female daily"
              title="Sorry :("
              text="There’s no data to display"
            />
          )
        ) : (
          <ErrorPlaceholder
            is_mobile={is_mobile}
            icon="https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/something-wrong-icon.svg"
            icon_alt="wrong icon female daily"
            title="Uh Oh!"
            text="Something went wrong"
          />
        )}
      </div>
      <style>
        {`
          .latest-article.section {
            display: flex;
            flex-direction: column;
            align-items: center;
            // margin: 100px 0 60px 0;
            // border: 10px solid #F0F3F7;
            padding: 10px;
            background-image: url('https://s3-ap-southeast-1.amazonaws.com/assets.femaledaily.com/editorial/papyrus.jpg');
            background-size: contain;
            background-repeat: repeat;
            background-position: top left;
          }

          .latest-article .section-title-wrapper {
            background: #FFFFFF;
            padding: 0 16px;
            width: fit-content;
            margin-top: -30px;
            z-index: 1;
          }

          .latest-article .section-title-wrapper .section-title {
            margin-bottom: 0;
          }

          .latest-article .section-title.bordered {
            width: fit-content;
          }

          .latest-article-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: 1200px;
            background-color: #FFF;
            padding-top: 60px;
            margin-top: -20px;
            justify-content: center;
            ${
              !Array.isArray(dataArticleLatest) || !dataArticleLatest.length
                ? 'padding-bottom: 32px;'
                : ''
            }
          }

          .latest-article-small {
            width: 25%;
            padding: 0 25px;
          }

          .latest-article-small .fdn-article-card-desktop-type-2 {
            margin-bottom: 43px;
          }

          .latest-article-small .fdn-article-card-desktop-type-2 .article-title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3; 
            -webkit-box-orient: vertical;
          }

          .latest-article-big {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
          }

        `}
      </style>
    </div>
  )
}

export default LatestArticleDesktop
